import Box from "@mui/material/Box";
import moment from "moment";
import DetailsBox from "parts/table/detailsBox";
import React, { useState } from "react";
import { IResponse, ThirdPartyLogDetailsData } from "_interfaces/log_alerts";
import { Button, Card, Grid, Typography } from "@mui/material";
import { getColor } from "../thirdPartyLogAlertsTable";
import CustomDrawer from "parts/customDialog/customDrawer";

interface Props {
    data: ThirdPartyLogDetailsData | null;
    fullWidth?: boolean;
    noPadding?: boolean;
    noBorder?: boolean;
}

const ThirdPartyLogAlertDetailsTable: React.FC<Props> = ({
    data,
    fullWidth,
    noPadding,
    noBorder,
}) => {
    const [openApiResponses, setOpenApiResponses] = useState<boolean>(false);

    const handleToggleApiResponses = () => setOpenApiResponses(!openApiResponses);

    const rows: {
        field: keyof NonNullable<
            ThirdPartyLogDetailsData
        >;
        headerName: string;
        renderCell?: (v: any) => void;
    }[] = [
            { field: "activity", headerName: "Activity" },
            {
                field: "alertLevel",
                headerName: "Alert Level",
                renderCell: (params) => (
                    <Box className="tw-capitalize" sx={{ color: getColor(params?.toLowerCase()) }} >
                        {params?.charAt(0).toUpperCase() + params?.slice(1).toLowerCase()}
                    </Box>
                )
            },
            { field: "level", headerName: "Level",renderCell: (params) => (
                <Box className="tw-capitalize" >
                    {params}
                </Box>
            ) },
            {
                field: "error",
                headerName: "Error",
            },
            { field: "moduleName", headerName: "Module Name" },
            { field: "activityId", headerName: "Activity ID" },
            { field: "signature", headerName: "Signature" },
            { field: "actionNeeded", headerName: "ActionNeeded" },
            { field: "user", headerName: "User" },
            { field: "createdAt", headerName: "Created On", renderCell: (v: string) => moment(v).format("Do MMM, YYYY"), },
            {
                field: "responses",
                headerName: "API Responses",
                renderCell(v) {
                    return data?.responses?.length ? (
                        <Button
                            onClick={handleToggleApiResponses}
                            variant="text"
                            size="small"
                            sx={{ fontSize: "inherit" }}
                        >
                            View Responses
                        </Button>
                    ) : (
                        <Box>N/A</Box>
                    );
                },
            },
        ];

    const apiResponsesRows: {
        field: keyof NonNullable<IResponse>;
        headerName: string;
        renderCell?: (v: any) => void;
    }[] = [
            {
                field: "responseCode",
                headerName: "Response Code",
            },
            { field: "retryCount", headerName: "Retry Count" },
            { field: "errorMessage", headerName: " Error Message" },
            {
                field: "response",
                headerName: "Response",
            },
            {
                field: "apiErrorMessage",
                headerName: "Api Error Message",
            },
        ];

    return (
        <>
            <Card sx={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "20px" }}>
                <Box className="tw-flex tw-justify-between tw-items-center">
                    <Typography color="text.primary" className="tw-text-[18px]" sx={{ fontWeight: "bold" }}>
                        {"Log & Alert Details"}
                    </Typography>
                </Box>
                {data?.id ? (
                    <DetailsBox
                        data={data}
                        rows={rows}
                        fullWidth={fullWidth}
                        noPadding={noPadding}
                        noBorder={noBorder}
                    />
                ) : (
                    <></>
                )}
            </Card>
            <CustomDrawer
                title={`Log Responses`}
                open={openApiResponses}
                onClose={handleToggleApiResponses}
            >
                <Grid container spacing={2}>
                    {data?.responses?.map((response, i) => (
                        <Grid item xs={12} key={i}>
                            <DetailsBox data={response} rows={apiResponsesRows} fullWidth />
                        </Grid>
                    ))}
                </Grid>
            </CustomDrawer>
        </>
    );
};

export default ThirdPartyLogAlertDetailsTable;
