import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { GetAllSimProps } from "_interfaces/functions/http-requests/simManagement";
import SimManagementTableInterface from "_interfaces/sim-management/simManagementTable";
import { AppStatusCode } from "config/appStatusCode";
import { filterNonNullValues } from "functions/helper";
import { GetAllSim, GetSimStockCount } from "functions/http-requests/simManagement";
import { RouteConstant } from "navigation/constant";
import SectionTitle from "parts/sectionTitle";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SimChart from "../../parts/charts/simChart";
import AlertCard from "./alertCard";
import AvailableStockChart from "./availableStockChart";
import SimManagementTable from "./simManagementTable";
import { toggleLoading } from "state/reducers/loading";
import { useDispatch } from "react-redux";
import { SimStockCountInterface } from "_interfaces/sim-management/details";
import { GetThirdPartyApiAlerts } from "functions/http-requests/log_alerts";
import { GetThirdPartyApiAlertsProps, ThirdPartyLogDetailsDashboard, ThirtPartyApiAlertsTable } from "_interfaces/log_alerts";

const DashboardLayout = () => {
  const navigate = useNavigate();

  const [activeMiniInventory, setActiveMiniInventory] = useState("physicalSim");

  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [simStatusCount, setSimStatusCount] = useState<SimStockCountInterface>({
    registerCount: 0,
    distributorCount: 0,
    inStockCount: 0
  });
  const Dispatch = useDispatch();
  const [alertData, setAlertData] = useState<ThirdPartyLogDetailsDashboard[]>([]);

  const [physicalData, setPhysicalData] = useState<
    SimManagementTableInterface["data"]
  >([]);

  const handleInventoryClick = (id: string) => {
    if (id === "all") {
      navigate(
        `${RouteConstant.SIM_MANAGEMENT}?sim-type=${activeMiniInventory}`
      );
      return;
    }
    setActiveMiniInventory(id);
  };

  const fetchSimCount = () => {
    Dispatch(toggleLoading(true));
    GetSimStockCount()
      .then((res) => {
        if (res?.data?.statusCode === AppStatusCode.api_success) {
          const DATA = res?.data?.data;
          setSimStatusCount(DATA[0])
        }
      })
      .catch(() => { })
      .finally(() => Dispatch(toggleLoading(false)));
  }

  useEffect(() => {
    fetchSimCount();
    if (activeMiniInventory !== "all") {
      let fetchList: (() => void) | null = () => {
        setDataLoading(true);
        let PAYLOAD_DATA: GetAllSimProps["DATA"] = {
          SIM_type: activeMiniInventory === "all" ? "" : activeMiniInventory,
          pageNumber: 1,
          pageSize: 10,
        };

        let FILTERED_PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

        GetAllSim({
          DATA: FILTERED_PAYLOAD_DATA,
        })
          .then((res) => {
            const data = res?.data;

            if (data?.statusCode === AppStatusCode.api_success) {
              let DATA: any = res?.data?.data;
              DATA = DATA?.map((item: any) => ({
                id: item?._id,
                ICCID: item?.ICCID || "",
                simType: item?.SIM_type || "",
                serviceType: item?.serviceType || "",
                status: item?.status || "",
                isReserved: item?.isReserved,
              }));
              setPhysicalData(DATA);
            } else {
              setPhysicalData([]);
            }
          })
          .catch(() => {
            setPhysicalData([]);
          })
          .finally(() => {
            setDataLoading(false);
          });
      };
      fetchList();
      return () => {
        fetchList = null;
      };
    }
  }, [activeMiniInventory]);

  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      setDataLoading(true);
      let PAYLOAD_DATA: GetThirdPartyApiAlertsProps["DATA"] = {
        pageNumber: "1",
        pageSize: "10",
      };

      GetThirdPartyApiAlerts({
        DATA: PAYLOAD_DATA,
      })
        .then((res) => {
          const data = res?.data;
          if (data?.statusCode === AppStatusCode.api_success) {

            let DATA: any = res?.data?.data;
            DATA = DATA?.map((item: any) => ({
              id: item?._id,
              activity: item?.activity || "",
              moduleName: item?.apiModule || "",
              message: item?.message || "",
              alertLevel: item.alertLevel || "",
            }));
            setAlertData(DATA);
          } else {
            setAlertData([]);
          }
        })
        .catch(() => {
          setAlertData([]);
        })
        .finally(() => {
          setDataLoading(false);
        });
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, []);

  const miniInventoryList = [
    {
      id: "physicalSim",
      label: "Physical SIM",
      component: (
        <SimManagementTable data={physicalData} loading={dataLoading} />
      ),
    },
    {
      id: "eSim",
      label: "E-SIM",
      component: (
        <SimManagementTable data={physicalData} loading={dataLoading} />
      ),
    },
    { id: "all", label: "View All" },
  ];
  return (
    <>
      <Box>
        <Grid container spacing={2}>
          {/* <Grid item xs={12} md={6}>
            <AvailableStockChart />
          </Grid> */}
          <Grid item xs={12} md={6}>
            <SimChart DATA={simStatusCount} />
          </Grid>
        </Grid>
      </Box>

      <Box pt={3}>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Box>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={4}>
                  <SectionTitle
                    title="Mini Inventory"
                    variant="h4"
                    fontWeight={600}
                    fontSize={18}
                  />
                </Grid>
                <Grid item xs={8} className="tw-flex tw-justify-end">
                  {miniInventoryList?.map(({ id, label }, i) => (
                    <Button
                      key={i}
                      variant="outlined"
                      color={
                        activeMiniInventory === id ? "primary" : "secondary"
                      }
                      className="tw-ml-[8px]"
                      onClick={() => handleInventoryClick(id)}
                    >
                      {label}
                    </Button>
                  ))}
                </Grid>
              </Grid>
            </Box>

            <Box pt={2}>
              {miniInventoryList?.map(({ id, component }, i) => (
                <Fragment key={i}>
                  {activeMiniInventory === id && component ? component : <></>}
                </Fragment>
              ))}
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box>
              <Box
                px={1}
                py={0.5}
                className="tw-flex tw-items-center tw-justify-between tw-border tw-rounded-md"
              >
                <SectionTitle
                  title="Alert"
                  variant="h4"
                  fontWeight={600}
                  fontSize={18}
                />
                <Button
                  variant="text"
                  color="secondary"
                  className="tw-text-[12px]"
                >
                  Clear All
                </Button>
              </Box>
            </Box>
            <Box>
              {
                alertData?.map(data => (
                  <AlertCard DATA={data} />
                ))
              }
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DashboardLayout;
