import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import axios from "axios";
import DatePicker from "component/_common/forms/date";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import InputCheckbox from "component/_common/forms/inputCheckbox";
import Text from "component/_common/forms/text";
import { AppStatusCode } from "config/appStatusCode";
import { generateAutocompleteOptions } from "functions/helper";
import { HTTP_ERROR } from "functions/http";
import {
  CreatePromotion,
  GetPromotionById,
  UpdatePromotion,
} from "functions/http-requests/promotions";
import {
  isValidString,
  renderInvalidStringErrorText,
} from "functions/validators";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import {
  ALLOWED_PROMOTION_APPLY_ON,
  ALLOWED_PROMOTION_CONSUMER,
  ALLOWED_PROMOTION_RECHARGE_DATA_UNIT,
  ALLOWED_PROMOTION_RECHARGE_SHOW_IN_PAGE,
  ALLOWED_PROMOTION_RECHARGE_TYPE,
  ALLOWED_PROMOTION_TYPE,
  ALLOWED_PROMOTION_UI_TEXT_TYPE,
  ALLOWED_PROMOTION_UI_THEME,
  PromotionCreateProps,
  PromotionUpdateProps,
} from "_interfaces/functions/http-requests/promotion";
import { FormInputProps } from "_interfaces/_common/forms";
import { PromotionModel } from "_models/data/promotion/data.promotion.model";
import { PromotionErrorModel } from "_models/error/promotion/error.promotion.model";
import { validatePromotion } from "./formValidator";
import PromotionTextTypesForm from "./promotionTextTypesForm";
import PromotionTextTypesTable from "./promotionTextTypesTable";

interface InputListProps extends FormInputProps {
  labelComponent?: React.ReactNode;
  afterComponent?: React.ReactNode;
  name:
    | "promotionType"
    | "promotionConsumer"
    | "amount"
    | "promotionApplyOn"
    | "rechargeType"
    | "rechargeDataUnit"
    | "rechargeAmountGroup"
    | "rechargeAmount_voice"
    | "rechargeAmount_data"
    | "rechargeAmount_sms"
    | "maxUse"
    | "showInPage"
    | "UIGroup"
    | "UI_theme"
    | "UI_textTypesGroup"
    | "assignDate"
    | "expiryDate"
    | "isActive";
}

interface InputListNestedProps extends InputListProps {
  groupItems?: InputListProps[];
}

interface PromotionFormInterface {
  editId?: string | null;
  handleRefresh: () => void;
  onClose: () => void;
}

const PromotionForm: React.FC<PromotionFormInterface> = ({
  editId,
  handleRefresh,
  onClose,
}) => {
  const Dispatch = useDispatch();

  const [state, setState] = useState<PromotionModel>(new PromotionModel());
  const [errors, setErrors] = useState<PromotionErrorModel>(
    new PromotionErrorModel()
  );

  const [openTextTypesForm, setOpenTextTypesForm] = useState<boolean>(false);

  const handleToggleTextTypesForm = () =>
    setOpenTextTypesForm(!openTextTypesForm);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setState((prev) => ({ ...prev, [name]: checked }));
    } else {
      if (name === "name" && value) {
        if (!isValidString(value)) {
          return setErrors((prev) => ({
            ...prev,
            [name]: renderInvalidStringErrorText(),
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            [name]: "",
          }));
        }
      }
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleAutoComplete = (
    e: React.SyntheticEvent | null,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    setState((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
            (e: { value: string | number; id: string | number }) =>
              e?.value || e?.id
          )
        : value?.value || value?.id,
    }));
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleCreate = ({ DATA }: PromotionCreateProps) => {
    Dispatch(toggleLoading(true));
    CreatePromotion({ DATA })
      .then((res) => {
        const data = res?.data;
        Dispatch(setAlert({ type: data?.level, message: data?.message }));
        if (
          data?.statusCode === AppStatusCode.api_created ||
          data?.statusCode === AppStatusCode.api_success
        ) {
          onClose();
          handleRefresh();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  const handleUpdate = ({ DATA }: PromotionUpdateProps) => {
    Dispatch(toggleLoading(true));
    UpdatePromotion({ DATA })
      .then((res) => {
        const data = res?.data;
        Dispatch(setAlert({ type: data?.level, message: data?.message }));
        if (
          data?.statusCode === AppStatusCode.api_updated ||
          data?.statusCode === AppStatusCode.api_success
        ) {
          onClose();
          handleRefresh();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    } = validatePromotion(state, !!editId);

    if (ValidateStep?.valid) {
      const DATA: PromotionCreateProps["DATA"] = {
        promotionType: state?.promotionType || undefined,
        promotionConsumer: state?.promotionConsumer?.length
          ? state.promotionConsumer
          : undefined,
        amount: state?.amount ? Number(state.amount) : undefined,
        promotionApplyOn: state?.promotionApplyOn || undefined,
        rechargeType: state?.rechargeType || undefined,
        rechargeDataUnit: state?.rechargeDataUnit || undefined,
        rechargeAmount: {
          data: state?.rechargeAmount_data
            ? Number(state.rechargeAmount_data)
            : 0,
          voice: state?.rechargeAmount_voice
            ? Number(state.rechargeAmount_voice)
            : 0,
          sms: state?.rechargeAmount_sms ? Number(state.rechargeAmount_sms) : 0,
        },
        maxUse: state?.maxUse ? Number(state.maxUse) : undefined,
        showInPage: state?.showInPage?.length ? state.showInPage : [],
        UI: state?.UI || undefined,
        assignDate: state?.assignDate || undefined,
        expiryDate: state?.expiryDate || undefined,
        isActive: state?.isActive,
      };

      if (state?.UI_theme) {
        DATA["UI"] = {
          ...DATA.UI,
          theme: state?.UI_theme,
        };
      }

      if (editId) {
        const EDIT_DATA: PromotionUpdateProps["DATA"] = {
          ...DATA,
          id: state?.id,
        };

        handleUpdate({ DATA: EDIT_DATA });
      } else handleCreate({ DATA });
    } else {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };

  // GET BY ID
  const handleFetchById = (ID: string) => {
    Dispatch(toggleLoading(true));
    GetPromotionById(ID)
      .then((res) => {
        const data = res?.data;
        if (data?.statusCode === AppStatusCode.api_success) {
          let DATA: any = data?.data;

          setState({
            id: DATA?._id || "",
            promotionType: DATA?.promotionType || "",
            promotionConsumer: DATA?.promotionConsumer || [],
            amount: DATA?.amount?.toString() || "",
            promotionApplyOn: DATA?.promotionApplyOn || "",
            rechargeType: DATA?.rechargeType || "",
            rechargeDataUnit: DATA?.rechargeDataUnit,
            rechargeAmount_voice: DATA?.rechargeAmount?.voice || "",
            rechargeAmount_data: DATA?.rechargeAmount?.data || "",
            rechargeAmount_sms: DATA?.rechargeAmount?.sms || "",
            maxUse: DATA?.maxUse || "",
            showInPage: DATA?.showInPage || [],
            UI: DATA?.UI || null,
            UI_theme: DATA?.UI?.theme?.toString() || "",
            assignDate: DATA?.assignDate || null,
            expiryDate: DATA?.expiryDate || null,
            isActive: DATA?.isActive,
          });
        } else {
          Dispatch(setAlert({ type: "error", message: data?.message }));
          setState(new PromotionModel());
          onClose();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  useEffect(() => {
    if (editId) {
      let fetchById: (() => void) | null = () => {
        handleFetchById(editId);
      };
      fetchById();
      return () => {
        fetchById = null;
      };
    }
  }, [editId]);

  const TextTypeButton = () => {
    const uiKeys = state?.UI ? Object.keys(state?.UI) : [];
    const missingTextTypes = ALLOWED_PROMOTION_UI_TEXT_TYPE.filter(
      (type) => !uiKeys.includes(type)
    );

    const isButtonEnabled = missingTextTypes.length > 0;

    return (
      <Box color="grey">
        <Button
          variant="outlined"
          color="inherit"
          onClick={handleToggleTextTypesForm}
          disabled={!isButtonEnabled}
        >
          Add Text Type
        </Button>
      </Box>
    );
  };

  const inputList: InputListNestedProps[] = [
    {
      type: "autoComplete",
      name: "promotionConsumer",
      label: "Promotion Consumer",
      placeholder: "Select promotion consumer",
      options: generateAutocompleteOptions(ALLOWED_PROMOTION_CONSUMER),
      multiple: true,
      grid: 12,
    },
    {
      type: "autoComplete",
      name: "promotionType",
      label: "Promotion Type",
      placeholder: "Select promotion type",
      options: generateAutocompleteOptions(ALLOWED_PROMOTION_TYPE),
      multiple: false,
      grid: 6,
    },
    {
      type: "number",
      name: "amount",
      label: "Amount",
      placeholder: "Enter amount",
      grid: 6,
    },
    {
      type: "autoComplete",
      name: "promotionApplyOn",
      label: "Promotion Apply On",
      placeholder: "Select apply on",
      options: generateAutocompleteOptions(ALLOWED_PROMOTION_APPLY_ON),
      multiple: false,
      grid: 6,
    },
    {
      type: "number",
      name: "maxUse",
      label: "Max Use",
      placeholder: "Enter Max use",
      InputProps: { inputProps: { min: 0 } },
      grid: 6,
    },
    {
      type: "autoComplete",
      name: "showInPage",
      label: "Show In Page",
      placeholder: "Select pages",
      options: generateAutocompleteOptions(
        ALLOWED_PROMOTION_RECHARGE_SHOW_IN_PAGE
      ),
      multiple: true,
      grid: 12,
    },
    {
      type: "autoComplete",
      name: "rechargeType",
      label: "Recharge Type",
      placeholder: "Select recharge type",
      options: generateAutocompleteOptions(ALLOWED_PROMOTION_RECHARGE_TYPE),
      multiple: false,
      grid: 6,
    },
    {
      type: "autoComplete",
      name: "rechargeDataUnit",
      label: "Recharge Data Unit",
      placeholder: "Select recharge data unit",
      options: generateAutocompleteOptions(
        ALLOWED_PROMOTION_RECHARGE_DATA_UNIT
      ),
      multiple: false,
      grid: 6,
    },
    {
      name: "rechargeAmountGroup",
      label: "Recharge Amount",
      groupItems: [
        {
          type: "number",
          name: "rechargeAmount_data",
          label: "Data Quantity",
          placeholder: "Enter quantity",
          InputProps: { inputProps: { min: 0 } },
          enabled: true,
          grid: 6,
        },
        {
          type: "number",
          name: "rechargeAmount_voice",
          label: "Voice Quantity",
          placeholder: "Enter quantity",
          InputProps: { inputProps: { min: 0 } },
          enabled: true,
          grid: 6,
        },
        {
          type: "number",
          name: "rechargeAmount_sms",
          label: "SMS Quantity",
          placeholder: "Enter quantity",
          InputProps: { inputProps: { min: 0 } },
          enabled: true,
          grid: 6,
        },
      ],
    },
    {
      name: "UIGroup",
      label: "UI Settings",
      groupItems: [
        {
          type: "autoComplete",
          name: "UI_theme",
          label: "UI Theme",
          placeholder: "Select UI theme",
          options: generateAutocompleteOptions(ALLOWED_PROMOTION_UI_THEME),
          multiple: false,
          enabled: true,
          grid: 6,
        },
      ],
    },
    {
      name: "UI_textTypesGroup",
      label: "UI Text Types",
      labelComponent: <TextTypeButton />,
      afterComponent: (
        <PromotionTextTypesTable
          state={state}
          setState={setState}
          handleToggleTextTypesForm={handleToggleTextTypesForm}
        />
      ),
    },
    {
      type: "date",
      name: "assignDate",
      label: "Assign Date",
      placeholder: "Assign Date",
      grid: 6,
    },
    {
      type: "date",
      name: "expiryDate",
      label: "Expiry Date",
      placeholder: "Expiry Date",
      grid: 6,
    },
    {
      type: "checkbox",
      name: "isActive",
      label: "Status",
      enabled: true,
      grid: 12,
    },
  ];

  return (
    <>
      <Box
        component="form"
        id="crete_edit_promotion_form"
        onSubmit={handleSubmit}
      >
        <Grid container rowSpacing={2} columnSpacing={2}>
          {inputList.map(
            (
              {
                type,
                name,
                label,
                placeholder,
                grid,
                autoComplete,
                disabled,
                options,
                multiple,
                InputProps,
                groupItems,
                multiline,
                labelComponent,
                afterComponent,
              },
              index
            ) => (
              <Fragment key={index}>
                <>
                  {type === "text" || type === "email" || type === "number" ? (
                    <Grid item xs={12} md={grid || 12}>
                      <Text
                        type={type}
                        name={name}
                        label={label}
                        placeholder={placeholder}
                        autoComplete={autoComplete || "off"}
                        value={state[name as keyof PromotionModel]}
                        errorText={errors[name as keyof PromotionErrorModel]}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        disabled={disabled}
                        InputProps={InputProps}
                        multiline={multiline}
                      />
                    </Grid>
                  ) : type === "autoComplete" ? (
                    <Grid item xs={12} md={grid || 12}>
                      <InputAutoComplete
                        name={name}
                        options={options || []}
                        label={label}
                        placeholder={placeholder}
                        onChange={(e, v, m) =>
                          handleAutoComplete(e, v, name, m)
                        }
                        value={
                          options &&
                          options.length &&
                          state?.[name as keyof PromotionModel]
                        }
                        errorText={errors[name as keyof PromotionErrorModel]}
                        onFocus={handleFocus}
                        multiple={multiple}
                      />
                    </Grid>
                  ) : type === "date" ? (
                    <Grid item xs={12} md={grid || 6}>
                      <DatePicker
                        name={name}
                        label={label}
                        placeholder={placeholder}
                        value={state[name as keyof PromotionModel]}
                        errorText={errors[name as keyof PromotionErrorModel]}
                        onChange={(value) => {
                          setState((prev) => ({
                            ...prev,
                            [name]: value,
                          }));
                          setErrors((prev) => ({
                            ...prev,
                            [name]: "",
                          }));
                        }}
                      />
                    </Grid>
                  ) : type === "checkbox" ? (
                    <Grid item xs={12} md={grid || 12}>
                      <InputCheckbox
                        name={name}
                        label={label}
                        value={state?.[name as keyof PromotionModel] as boolean}
                        onChange={handleChange}
                      />
                    </Grid>
                  ) : name === "rechargeAmountGroup" ||
                    name === "UIGroup" ||
                    name === "UI_textTypesGroup" ? (
                    <Grid item xs={12}>
                      <Box
                        color="primary.main"
                        pb={1}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <>
                          {label}
                          {labelComponent ? labelComponent : <></>}
                        </>
                      </Box>
                      <Box pb={2} borderBottom={1} borderColor="inherit">
                        <Grid container rowSpacing={1} columnSpacing={2}>
                          {groupItems?.map((groupItem, groupIndex) => (
                            <Fragment key={groupIndex}>
                              {groupItem?.enabled ? (
                                <>
                                  {groupItem?.type === "text" ||
                                  groupItem?.type === "email" ||
                                  groupItem?.type === "number" ? (
                                    <Grid
                                      item
                                      xs={12}
                                      md={groupItem?.grid || 12}
                                    >
                                      <Text
                                        type={groupItem?.type}
                                        name={groupItem?.name}
                                        label={groupItem?.label}
                                        placeholder={groupItem?.placeholder}
                                        autoComplete={
                                          groupItem?.autoComplete || "off"
                                        }
                                        value={
                                          state[
                                            groupItem?.name as keyof PromotionModel
                                          ]
                                        }
                                        errorText={
                                          errors[
                                            groupItem?.name as keyof PromotionErrorModel
                                          ]
                                        }
                                        onChange={handleChange}
                                        onFocus={handleFocus}
                                        disabled={groupItem?.disabled}
                                        InputProps={groupItem?.InputProps}
                                      />
                                    </Grid>
                                  ) : groupItem?.type === "autoComplete" ? (
                                    <Grid
                                      item
                                      xs={12}
                                      md={groupItem?.grid || 12}
                                    >
                                      <InputAutoComplete
                                        name={groupItem?.name}
                                        options={groupItem?.options || []}
                                        label={groupItem?.label}
                                        placeholder={groupItem?.placeholder}
                                        onChange={(e, v, m) =>
                                          handleAutoComplete(
                                            e,
                                            v,
                                            groupItem?.name,
                                            m
                                          )
                                        }
                                        value={
                                          groupItem?.options &&
                                          groupItem?.options.length &&
                                          state?.[
                                            groupItem?.name as keyof PromotionModel
                                          ]
                                        }
                                        errorText={
                                          errors[
                                            groupItem?.name as keyof PromotionErrorModel
                                          ]
                                        }
                                        onFocus={handleFocus}
                                        multiple={groupItem?.multiple}
                                        disabled={groupItem?.disabled}
                                      />
                                    </Grid>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </Fragment>
                          ))}
                        </Grid>
                      </Box>
                      {afterComponent ? afterComponent : <></>}
                    </Grid>
                  ) : (
                    <></>
                  )}
                </>
              </Fragment>
            )
          )}

          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
              className="tw-capitalize tw-py-[12px]"
            >
              {editId ? "Save Changes" : "Add New Promotion"}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Dialog open={openTextTypesForm}>
        <DialogContent>
          <PromotionTextTypesForm
            onClose={handleToggleTextTypesForm}
            state={state}
            setState={setState}
            errors={errors}
            setErrors={setErrors}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PromotionForm;
