import {
  AgentUpdateProps,
  AgentUploadDocsProps,
} from "_interfaces/functions/http-requests/agents";
import { UpdateRegionalDistributorProps } from "_interfaces/functions/http-requests/distributor-channels/regional-distributors";

export const BASE_URL =
  process.env.REACT_APP_ENVIRONMENT === "prod"
    ? "https://inventoryapi.talkiomobile.com/api/v1"
    : "https://staginginventoryapi.talkiomobile.com/api/v1";

export const COUNTRY_CODE = "256";

/* ================== AUTH ================== */
export const GET_IP_INFO = `https://api64.ipify.org?format=json`;

export const AUTH_LOGIN = `/auth/login`;
export const AUTH_LOGIN_OTP_VERIFICATION = `/auth/login-otp-verification`;
export const AUTH_RESEND_LOGIN_OTP = `/auth/resend-login-otp`;
export const AUTH_CHECK = `/auth/auth-check`;
export const AUTH_LOGOUT = `/auth/logout`;
export const CHANGE_PASSWORD_REQUEST = `/user/change-password-request`;
export const VERIFY_OTP_PASSWORD_REQUEST = `/user/verify-otp-password-request`;
export const RESEND_PASSWORD_REQUEST_OTP = `/user/resend-password-request-otp`;
export const CHANGE_PASSWORD = `/user/change-password`;

/* ================== User ================== */
export const USER_ACTIVATION = `/user/user-activation`;
export const USER_GET_BY_ID = (ID: string) => `/user/get-by-id/${ID}`;
export const SERVICE_USER_GET_BY_ID = (ID: string) =>
  `/service-user/get-by-id/${ID}`;
export const BLOCK_UNBLOCK_SERVICE_USER = `/service-user/block-unblock`;

/* ================== VERIFICATION ================== */

export const VERIFICATION_VERIFY = `/verification/verify`;
export const VERIFICATION_SEND_SMS_OTP = `/verification/send-sms-otp`;
export const VERIFICATION_VERIFY_SMS_OTP = `/verification/verify-sms-otp`;

/* ================== ADDRESS ================== */
// CALL service API
export const GET_ALL_REGIONS = `/address/get-all-regions`;

export const GET_ALL_DISTRICT_BY_REGION = (REGION_ID: string) =>
  `/address/get-all-district-by-region/${REGION_ID}`;
export const GET_ALL_DISTRICT_BY_REGION_NAME = (REGION_NAME: string) =>
  `/address/get-all-district-by-regionName/${REGION_NAME}`;

export const GET_ALL_COUNTY_BY_DISTRICT = (DISTRICT_ID: string) =>
  `/address/get-all-county-by-district/${DISTRICT_ID}`;
export const GET_ALL_COUNTY_BY_DISTRICT_NAME = (DISTRICT_NAME: string) =>
  `/address/get-all-county-by-districtName/${DISTRICT_NAME}`;

export const GET_ALL_SUB_COUNTY_BY_COUNTY = (COUNTY_ID: string) =>
  `/address/get-all-sub-county-by-county/${COUNTY_ID}`;
export const GET_ALL_SUB_COUNTY_BY_COUNTY_NAME = (COUNTY_NAME: string) =>
  `/address/get-all-sub-county-by-countyName/${COUNTY_NAME}`;

/* ================== AGENT REGISTRATION ================== */

export const AGENT_GET_ALL_BY_DISTRIBUTOR = `/agent/get-all-by-distributor`;
export const AGENT_GET_BY_ID = (ID: string) => `/agent/get-by-id/${ID}`;
export const AGENT_GET_BY_MSISDN = (MSISDN: string) =>
  `/agent/get-by-MSISDN/${MSISDN}`;
export const AGENT_CREATE = `/agent/create`;
export const AGENT_UPDATE = (ID: AgentUpdateProps["ID"]) =>
  `/agent/update/${ID}`;
export const AGENT_UPLOAD_DOCS = (ID: AgentUploadDocsProps["ID"]) =>
  `/agent/upload-agent-docs/${ID}`;
export const AGENT_APPROVE = (ID: string) => `/agent/approve-agent/${ID}`;

export const AGENT_GET_BY_USER_ID = (ID: string) =>
  `/agent/get-by-user-id/${ID}`;

/* ================== DISTRIBUTOR ================== */
export const DISTRIBUTOR_GET_BY_USER_ID = (ID: string) =>
  `/regional-distributer/get-by-user/${ID}`;

/* ================== DISTRIBUTOR CHANNELS ================== */
// REGIONAL DISTRIBUTOR
export const CREATE_REGIONAL_DISTRIBUTOR = `/regional-distributer/create`;

export const UPDATE_REGIONAL_DISTRIBUTOR = (
  ID: UpdateRegionalDistributorProps["ID"]
) => `/regional-distributer/update/${ID}`;

export const GET_REGIONAL_DISTRIBUTOR = `/regional-distributer/get-all`;

export const GET_REGIONAL_DISTRIBUTOR_BY_ID = (ID: string) =>
  `/regional-distributer/get-by-id/${ID}`;

/* ================== USER MANAGEMENT ================== */
// INVENTORY USERS
export const CREATE_INVENTORY_USERS = `/user/create-user`;
export const UPDATE_INVENTORY_USERS = `/user/update-user`;
export const GET_INVENTORY_USERS = `/user/get-users`;
export const GET_INVENTORY_USERS_BY_ID = (ID: string) =>
  `/user/get-by-id/${ID}`;
export const INVENTORY_USER_ACTIVATION = `/user/user-activation`;

/* ================== KYC ================== */
export const GET_KYC_BY_ID = (ID: string) => `/kyc/get-by-id/${ID}`;

// FOR NIRA & UCC
export const NIRA_UCC_REGISTRATION_RE_SUBMIT_REQUEST = `/registration/re-submit-request`;

// FOR CBS
export const CBS_RE_SUBMIT_REQUEST = `/cbs/re-submit-request`;

export const CREATE_HLR = `/hlrHss/create-hlr`;
export const CREATE_PDP = `/hlrHss/create-pdp`;
export const CREATE_OCSI = `/hlrHss/create-ocsi`;
export const CREATE_SMSCI = `/hlrHss/create-smsci`;
export const CREATE_SUPPLEMENT1 = `/hlrHss/create-supplement1`;
export const CREATE_SUPPLEMENT2 = `/hlrHss/create-supplement2`;
export const CREATE_HSS = `/hlrHss/create-hss`;
export const CREATE_APN = `/hlrHss/create-apn`;
export const CREATE_TCSI = `/hlrHss/create-tcsi`;
export const E_SIM_DOWNLOAD_ORDER = `/hlrHss/e-sim-download-order`;
export const E_SIM_CONFIRM_ORDER = `/hlrHss/e-sim-confirm-order`;

/* ================== CUSTOMER MANAGEMENT ================== */
export const GET_ALL_CUSTOMERS = `/customer/get-all-customers`;
export const GET_CUSTOMER_BY_ID = (ID: string) => `/customer/get-by-id/${ID}`;
export const GET_CUSTOMER_BY_AGENT_ID = `/customer/get-by-agent`;
export const GET_CUSTOMER_BY_DISTRIBUTOR_ID = `/customer/get-by-distributor`;
export const GET_CUSTOMER_DOC = (ID: string) =>
  `/customer/get-customer-doc/${ID}`;
export const GET_CUSTOMER_COUNT_BY_DISTRIUTOR = (ID: string) =>
  `/customer/get-customers-count-by-distributor/${ID}`;

/* ================== SIM CARD CHARGES ================== */
export const CREATE_SIM_CARD_CHARGES = `/sim-card-charges/create`;
export const UPDATE_SIM_CARD_CHARGES = `/sim-card-charges/update`;
export const GET_SIM_CARD_CHARGES = `/sim-card-charges/get-all`;
export const GET_SIM_CARD_CHARGES_BY_ID = (ID: string) =>
  `/sim-card-charges/get-by-id/${ID}`;
export const GET_SIM_CARD_CHARGES_BY_MSISDN_CATEGORY = (CATEGORY: string) =>
  `/sim-card-charges/get-by-msmisdn-category/${CATEGORY}`;

/* ================== PACKAGE BUNDLE ================== */
export const CREATE_PACKAGE_BUNDLE = `/package-bundle/create`;
export const UPDATE_PACKAGE_BUNDLE = `/package-bundle/update`;
export const GET_PACKAGE_BUNDLE = `/package-bundle/get-all`;
export const GET_PACKAGE_BUNDLE_BY_ID = (ID: string) =>
  `/package-bundle/get-by-id/${ID}`;

/* ================== SIM MANAGEMENT ================== */
export const UPLOAD_SIM_CARTON = `/sim/upload-carton`;
export const GET_ALL_SIM = `/sim/get-all-sim`;
export const GET_SIM_BY_ID = (ID: string) => `/sim/get-by-id/${ID}`;
export const GET_SIM_BY_ICCID = (ICCID: string) => `/sim/get-by-iccid/${ICCID}`;
export const GET_ALL_SIM_COUNT = `/sim/get-all-sim-count`;
export const GET_SIM_STOCK_COUNT = `/sim/sim-stock-count`;

/* ================== MSISDN MANAGEMENT ================== */
export const UPLOAD_MSISDN = `/msisdn/upload-bulk`;
export const GET_ALL_MSISDN = `/msisdn/get-all-msisdn`;
export const GET_MSISDN_BY_ID = (ID: string) => `/msisdn/get-by-id/${ID}`;
export const MSISDN_TYPE_UPDATE = (ID: string) =>
  `/msisdn/type-update-by-msisdn/${ID}`;
export const MSISDN_STATUS_UPDATE = (ID: string) =>
  `/msisdn/status-update-by-msisdn/${ID}`;
export const GET_MSISDN_BY_MSISDN = (MSISDN: string) =>
  `/msisdn/get-by-msisdn/${MSISDN}`;
export const GET_ALL_MSISDN_COUNT = `/msisdn/get-all-msisdn-count`;

/* ================== INTER SWITCH E VALUE ================== */
export const GET_INTER_SWITCH_E_VALUE = `/inter-switch-e-value/get`;
export const ADD_BALANCE_INTER_SWITCH_E_VALUE = `/inter-switch-e-value/add-balance`;
export const BLOCK_UNBLOCK_INTER_SWITCH_E_VALUE = `/inter-switch-e-value/block-unblock`;

/* ================== AGENT REGISTRATION ================== */
export const GET_ALL_AGENT = `/agent/get-all`;
export const COUNT_BY_AGENT = (ID: string) =>
  `/customer/get-customers-count-by-agent/${ID}`;
export const GET_HANDLER_BY_AGENT = `/handler/get-all-by-agent`;

/* ================== HANDLER ================== */
export const GET_ALL_HANDLERS = `/handler/get-all`;
export const COUNT_BY_HANDLER = (ID: string) =>
  `/customer/get-counting-by-handler/${ID}`;
export const GET_HANDLER_BY_ID = (ID: string) => `/handler/get-by-id/${ID}`;
export const GET_CUSTOMER_BY_HANDLER = `customer/get-by-handler`;

/* ================== APPS VERSION ================== */
export const GET_LATEST_VERSION = (AppName: string) =>
  `/app-version/get-latest-version/${AppName}`;
export const GET_ALL_VERSION = `/app-version/get-all-version`;
export const CREATE_NEW_VERSION = `/app-version/create-new-version`;

/* ================== PROMOTION ================== */
export const GET_ALL_PROMOTION = `/promotion/get-all`;
export const GET_PROMOTION_BY_ID = (ID: string) => `/promotion/get-by-id/${ID}`;
export const CREATE_PROMOTION = `/promotion/create`;
export const UPDATE_PROMOTION = `/promotion/update`;

/* ================== CARTON ================== */
export const CARTON_LIST_BY_AGENT = (ID: string) => `/carton/list-by-agent/${ID}`;
export const CARTON_LIST_BY_DISTRIBUTOR = (ID: string) => `/carton/list-by-distributer/${ID}`;
export const ASSIGN_CARTON_TO_DISTRIBUTOR = (ID: string) => `/carton/assign-to-distributer/${ID}`;
export const ASSIGN_CARTON_TO_AGENT = (ID: string) => `/carton/assign-to-agent/${ID}`;
export const GET_ALL_CARTONS = "/carton/get-all-carton";

/* ================== LOG & ALERTS ================== */
export const GET_THIRD_PARTY_API_ALERTS = "/alert/third_party_api_alert_list";
export const GET_THIRD_PARTY_API_ALERT_DETAILS = (ID: string) => `/alert/third_party_api_alert_details/${ID}`;
export const GET_GLOBAL_ACTIVITY_LOGS_LIST = "/activity/global_activity_log_list";
export const GET_GLOBAL_ACTIVITY_LOG_DETAILS = (ID: string) => `/activity/global_activity_log_details/${ID}`;
