export type PromotionTypeProps = "simBooking" | "registration" | "recharge";
export type PromotionConsumerProps = "citizen" | "tourist" | "refugee";
export type PromotionApplyOnProps = "recharge" | "totalAmount";
export type PromotionRechargeTypeProps = "airtime" | "buildBundle" | "bundle";
export type PromotionRechargeDataUnitProps = "mb" | "gb";
export type PromotionRechargeAmountProps = {
  voice: number;
  data: number;
  sms: number;
};
export type PromotionShowInPage =
  | "home"
  | "products"
  | "products-build-bundle"
  | "products-premium-plans";
export type PromotionTextTypeSizeProps = "1" | "2" | "3" | "4" | "5";
export type PromotionTextTypePositionProps =
  | "left"
  | "right"
  | "bottom"
  | "top"
  | "middle";
export type PromotionTextTypeProps = {
  text: string;
  size: PromotionTextTypeSizeProps;
  color: string;
  position: PromotionTextTypePositionProps;
  linePosition: number;
  show: boolean;
};
export type PromotionUiThemeProps = "1" | "2" | "3" | "4";
export type PromotionUiProps = {
  heading?: PromotionTextTypeProps;
  title1?: PromotionTextTypeProps;
  title2?: PromotionTextTypeProps;
  title3?: PromotionTextTypeProps;
  title4?: PromotionTextTypeProps;
  description?: PromotionTextTypeProps;
  theme?: PromotionUiThemeProps;
};
export type PromotionUiTextTypeProps =
  | "heading"
  | "title1"
  | "title2"
  | "title3"
  | "title4"
  | "description";

export const ALLOWED_PROMOTION_TYPE = [
  "simBooking",
  "registration",
  "recharge",
];

export const ALLOWED_PROMOTION_CONSUMER = ["citizen", "tourist", "refugee"];

export const ALLOWED_PROMOTION_APPLY_ON = ["recharge", "totalAmount"];

export const ALLOWED_PROMOTION_RECHARGE_TYPE = [
  "airtime",
  "buildBundle",
  "bundle",
];

export const ALLOWED_PROMOTION_RECHARGE_DATA_UNIT = ["mb", "gb"];

export const ALLOWED_PROMOTION_RECHARGE_SHOW_IN_PAGE = [
  "home",
  "products",
  "products-build-bundle",
  "products-premium-plans",
];

export const ALLOWED_PROMOTION_TEXT_TYPE_POSITION = [
  "left",
  "right",
  "bottom",
  "top",
  "middle",
];

export const ALLOWED_PROMOTION_UI_THEME = ["1", "2", "3", "4"];

export const ALLOWED_PROMOTION_UI_TEXT_TYPE = [
  "heading",
  "title1",
  "title2",
  "title3",
  "title4",
  "description",
];

export const ALLOWED_PROMOTION_UI_TEXT_SIZE = ["1", "2", "3", "4", "5"];

export type PromotionCreateProps = {
  DATA: {
    promotionType?: PromotionTypeProps;
    promotionConsumer?: PromotionConsumerProps[];
    amount?: number; //**price or percentage
    promotionApplyOn?: PromotionApplyOnProps;
    rechargeType?: PromotionRechargeTypeProps;
    rechargeDataUnit?: PromotionRechargeDataUnitProps;
    rechargeAmount?: PromotionRechargeAmountProps;
    maxUse?: number;
    showInPage?: PromotionShowInPage[];
    UI?: PromotionUiProps;
    assignDate?: Date;
    expiryDate?: Date;
    isActive?: boolean;
  };
};

export type PromotionUpdateProps = {
  DATA: PromotionCreateProps["DATA"] & {
    id?: string;
  };
};
