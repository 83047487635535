import WarningIcon from "@mui/icons-material/Warning";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { alpha, useTheme } from "@mui/material/styles";
import { ThirdPartyLogDetailsDashboard } from "_interfaces/log_alerts";
import React from "react";

interface Props {
  DATA: ThirdPartyLogDetailsDashboard

}

const AlertCard: React.FC<Props> = ({ DATA }) => {
  const { palette } = useTheme();
  const getColor = (value: string) => {
    if (!value) return;
    if (value == "veryhigh") {
      return "error";
    }
    if (value == "high") {
      return "warning";
    }
    if (value == "medium") {
      return "inherit";
    }
    return "success";
  }
  return (
    <>
      <Box
        mt={2}
        p={1}
        className="tw-rounded-md tw-border tw-flex tw-items-center tw-justify-between"
      >
        <Box className="tw-flex tw-items-center" width={350}>
          <Box
            className="tw-[48px] tw-h-[48px] tw-rounded-full tw-flex tw-items-center tw-justify-center"
            sx={{
              backgroundColor: alpha(palette.primary.main, 0.1),
              color: palette.primary.main,
              flex: "0 0 48px",
            }}
          >
            <WarningIcon color={getColor(DATA.alertLevel.toLowerCase())} />
          </Box>
          <Box pl={1}>
            <Typography>{DATA?.message}</Typography>
            <Typography fontSize={12}>{DATA?.moduleName} </Typography>
            <Typography fontSize={12}>{DATA?.activity}</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AlertCard;
