import Box from "@mui/material/Box";
import moment from "moment";
import DetailsBox from "parts/table/detailsBox";
import React, { useState } from "react";
import { IResponse, ThirdPartyLogDetailsData } from "_interfaces/log_alerts";
import { Button, Card, Grid, Typography } from "@mui/material";
import CustomDrawer from "parts/customDialog/customDrawer";
import { getColor } from "../globalActivityLogsTable";
import { IGlobalActivityLog } from "_interfaces/global-activity-logs";

interface Props {
    data: IGlobalActivityLog | null;
    fullWidth?: boolean;
    noPadding?: boolean;
    noBorder?: boolean;
}

const GlobalActivityLogDetailsTable: React.FC<Props> = ({
    data,
    fullWidth,
    noPadding,
    noBorder,
}) => {
    const [openApiResponses, setOpenApiResponses] = useState<boolean>(false);

    const handleToggleApiResponses: any = () => setOpenApiResponses(!openApiResponses);

    const rows: {
        field: keyof NonNullable<
            IGlobalActivityLog
        >;
        headerName: string;
        renderCell?: (v: any) => void;
    }[] = [
            { field: "activity", headerName: "Activity" },
            {
                field: "alertLevel",
                headerName: "Alert Level",
                renderCell: (params) => (
                    <Box className="tw-capitalize" sx={{ color: getColor(params?.toLowerCase()) }} >
                        {params?.charAt(0).toUpperCase() + params?.slice(1).toLowerCase()}
                    </Box>
                )
            },
            {
                field: "level", headerName: "Level", renderCell: (params) => (
                    <Box className="tw-capitalize" >
                        {params}
                    </Box>
                )
            },
            {
                field: "error",
                headerName: "Error",
            },
            { field: "moduleName", headerName: "Module Name" },
            { field: "signature", headerName: "Signature" },
            { field: "actionNeeded", headerName: "ActionNeeded" },
            { field: "actionToServer", headerName: "Action To Server" },
            { field: "actionFromServer", headerName: "Action From Server" },
            { field: "actionId", headerName: "Action ID" },
            { field: "IP", headerName: "IP" },
            { field: "message", headerName: "Message" },
            { field: "error", headerName: "Error" },
            { field: "user", headerName: "User" },
            { field: "auth3Id", headerName: "Auth3 ID" },
            { field: "userID", headerName: "User ID" },
            { field: "time", headerName: "Time", renderCell: (v: string) => moment(v).format("Do MMM, YYYY"), },
            { field: "createdAt", headerName: "Created On", renderCell: (v: string) => moment(v).format("Do MMM, YYYY"), },
            {
                field: "otherInfo", headerName: "Other Info",
                renderCell(v) {
                    return data?.otherInfo && (
                        <Button
                            onClick={handleToggleApiResponses}
                            variant="text"
                            size="small"
                            sx={{ fontSize: "inherit" }}
                        >
                            View Info
                        </Button>
                    );
                },
            },
        ];

    const apiResponsesRows: {
        field: keyof NonNullable<any>;
        headerName: string;
        renderCell?: (v: any) => void;
    }[] = [
            {
                field: "transactionId",
                headerName: "Transaction ID",
            },
            {
                field: "firstMSISDN",
                headerName: "First MSISDN",
            },
            {
                field: "lastMSISDN",
                headerName: "Last MSISDN",
            },
            {
                field: "startICCID",
                headerName: "Start ICCID",
            },
            {
                field: "lastICCID",
                headerName: "Last ICCID",
            },
        ];


    return (
        <>
            <Card sx={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "20px" }}>
                <Box className="tw-flex tw-justify-between tw-items-center">
                    <Typography color="text.primary" className="tw-text-[18px]" sx={{ fontWeight: "bold" }}>
                        {"Log & Alert Details"}
                    </Typography>
                </Box>
                {data?.id ? (
                    <DetailsBox
                        data={data}
                        rows={rows}
                        fullWidth={fullWidth}
                        noPadding={noPadding}
                        noBorder={noBorder}
                    />
                ) : (
                    <></>
                )}
            </Card>
            <CustomDrawer
                title={`Other Information`}
                open={openApiResponses}
                onClose={handleToggleApiResponses}
            >

                <Grid item xs={12}>
                    <DetailsBox data={data?.otherInfo} rows={apiResponsesRows as any} fullWidth />
                </Grid>

            </CustomDrawer>
        </>
    );
};

export default GlobalActivityLogDetailsTable;
