interface Props {
  id?: string;
  promotionType?: string;
  promotionConsumer?: string;
  amount?: string;
  promotionApplyOn?: string;
  rechargeType?: string;
  rechargeDataUnit?: string;
  rechargeAmount_voice?: string;
  rechargeAmount_data?: string;
  rechargeAmount_sms?: string;
  maxUse?: string;
  showInPage?: string;
  UI?: string;
}

export class PromotionErrorModel {
  id?: string;
  promotionType?: string;
  promotionConsumer?: string;
  amount?: string;
  promotionApplyOn?: string;
  rechargeType?: string;
  rechargeDataUnit?: string;
  rechargeAmount_voice?: string;
  rechargeAmount_data?: string;
  rechargeAmount_sms?: string;
  maxUse?: string;
  showInPage?: string;
  UI?: string;

  constructor(data: Props = {}) {
    this.id = data.id || "";
    this.promotionType = data.promotionType || "";
    this.promotionConsumer = data.promotionConsumer || "";
    this.amount = data.amount || "";
    this.promotionApplyOn = data.promotionApplyOn || "";
    this.rechargeType = data.rechargeType || "";
    this.rechargeDataUnit = data.rechargeDataUnit || "";
    this.rechargeAmount_voice = data.rechargeAmount_voice || "";
    this.rechargeAmount_voice = data.rechargeAmount_voice || "";
    this.rechargeAmount_voice = data.rechargeAmount_voice || "";
    this.maxUse = data.maxUse || "";
    this.showInPage = data.showInPage || "";
    this.UI = data.UI || "";
  }
}
